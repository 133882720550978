/***** Fonts *****/
/***** Colors *****/
/***** Texts sizes *****/
/***** transition *****/
.transition-main {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }

/***** cookie ****/
.picto-home {
  padding-top: 100px;
  position: relative; }
  .picto-home img {
    height: 100px;
    width: 130px; }
  .picto-home p {
    margin: 0 25%; }

.nos-services {
  position: relative;
  padding-top: 100px; }
  .nos-services .vague::after {
    content: "";
    background: url("../../../../../web/images/maison-de-la-literie/vague.svg");
    position: absolute;
    width: 100%;
    height: 40px;
    background-size: 160%;
    background-repeat: repeat-x;
    bottom: 0;
    left: 0; }
  .nos-services img {
    height: 100%;
    width: 100%; }
  .nos-services .bg-gray {
    background-color: #f6f6f6;
    margin-top: -35px;
    margin-bottom: -35px; }
    .nos-services .bg-gray .nos-services-text {
      padding: 50px 14%; }
      .nos-services .bg-gray .nos-services-text ul {
        padding: 0; }
        .nos-services .bg-gray .nos-services-text ul li {
          list-style: none; }
          .nos-services .bg-gray .nos-services-text ul li::before {
            content: "\2022";
            color: #003657;
            font-weight: bold;
            display: inline-block;
            width: 1em; }

.nos-marques {
  padding-top: 110px; }
  .nos-marques .slider-marques {
    width: 100%;
    margin: 0 auto; }
    .nos-marques .slider-marques img {
      height: 45px;
      margin-top: 70px; }

.sur-mesure {
  position: relative;
  background: url("../../../../../web/images/maison-de-la-literie/home_mesure.jpg");
  background-size: cover;
  height: 400px;
  width: 100%;
  background-position: center;
  margin-top: 110px; }
  .sur-mesure .bt_learn_more {
    background: #fff; }
    .sur-mesure .bt_learn_more:hover {
      background: #003657;
      color: #fff; }
  .sur-mesure h2 {
    padding-top: 90px; }
  .sur-mesure::after {
    content: "";
    background: url("../../../../../web/images/maison-de-la-literie/vague-2.svg");
    position: absolute;
    width: 100%;
    height: 10%;
    background-size: 100%;
    background-repeat: repeat-x;
    bottom: 0;
    left: 0; }

.professionnel {
  background-color: #f6f6f6;
  padding-top: 150px;
  padding-bottom: 100px; }
  .professionnel .professionnel-text {
    margin: 50px 50px 50px 90px; }
    .professionnel .professionnel-text h2 {
      padding-bottom: 60px; }
    .professionnel .professionnel-text p {
      color: #003657; }

@media all and (max-width: 1199px) {
  margin: 25px 50px 0 50px; }

@media all and (max-width: 991px) {
  .picto-home p {
    font-size: 16px;
    margin: 0; }
  .nos-services .container-fluid {
    padding: 0; }
  .nos-services .row {
    display: unset; }
  .nos-services .bg-gray {
    margin-top: -95px;
    margin-left: 5%;
    width: 90%; }
  .sur-mesure:after {
    height: 20%;
    background-size: 180%;
    background-repeat: repeat-x;
    bottom: -20px;
    left: 0;
    background-position: center; }
  .professionnel {
    padding-top: 0;
    padding-bottom: unset; }
    .professionnel .col-lg-5 {
      padding-bottom: 75px; } }

@media all and (max-width: 575px) {
  .picto-home {
    padding-top: 50px; }
  .nos-services .bg-gray {
    margin-top: -50px; }
    .nos-services .bg-gray .nos-services-text {
      padding: 50px 5% 100px 5%; }
      .nos-services .bg-gray .nos-services-text h2 {
        font-size: 24px; }
  .nos-services .vague:after {
    bottom: -15px; }
  .nos-services .bt_learn_more {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    text-align: center; }
  .nos-marques {
    padding-top: 50px; }
    .nos-marques h2 {
      font-size: 24px;
      margin: 0 5px; }
  .sur-mesure h2 {
    font-size: 24px;
    padding-top: 45px; }
  .sur-mesure:after {
    bottom: -30px; }
  .sur-mesure p {
    margin: 0 20px; }
  .professionnel .professionnel-text {
    margin: 10% 10px 0 10px; }
    .professionnel .professionnel-text h2 {
      font-size: 24px; } }
